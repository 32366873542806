import * as React from "react";

import "./Heading.css";

export default function Heading() {
  return (
    <header>
      <h1>Hi 👋</h1>
    </header>
  );
}
